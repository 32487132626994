<template>
  <div>
    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="$emit('update:isShow', false)"
      :isShow.sync="isShow"
    >
      <div slot="title" class="title">历史单据</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <div style="margin-bottom: 10px">
          <span class="search_center">
            <span>单据编号：</span>
            <el-input
              v-model="parameter.expenseNumber"
              style="width: 180px; margin-right: 10px"
              placeholder="请输入单据编号"
              clearable
            >
            </el-input>
          </span>
          <span class="search_center">
            <span>收款单位：</span>
            <el-input
              v-model="parameter.collectionUnit"
              style="width: 180px; margin-right: 10px"
              placeholder="请输入收款单位"
              clearable
            >
            </el-input>
          </span>
          <span class="search_center" v-if="prefixion === 'payment'">
            <span> 项目名称：</span>
            <el-select
              v-model="projectId"
              clearable
              filterable
              placeholder="请输入或选择项目名"
              style="width: 280px; margin-right: 10px"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span class="search_center" v-if="prefixion === 'businessOutsourceCost'">
            <span> 商务名称：</span>
            <el-select
              v-model="businessManageId"
              clearable
              filterable
              placeholder="请输入或选择商务名"
              style="width: 280px; margin-right: 10px"
            >
              <el-option
                v-for="item in BusinessList"
                :key="item.id"
                :label="item.businessName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <el-button type="primary" icon="el-icon-search" @click="onSearchFn">搜索</el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
        <el-table :data="tableData" border>
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            prop="expenseNumber"
            label="单据编号"
            align="center"
            width="175"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            v-if="prefixion === 'payment'"
            sortable
            label="项目名称"
            :show-overflow-tooltip="false"
            min-width="320"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="businessName"
            v-if="prefixion === 'businessOutsourceCost'"
            sortable
            label="商务名称"
            :show-overflow-tooltip="false"
            min-width="320"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="collectionUnit"
            sortable
            min-width="320"
            :show-overflow-tooltip="false"
            label="收款单位"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="sumCost" width="110" sortable label="申请金额" align="center">
            <template slot-scope="scope">
              {{ scope.row.sumCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="projectBudget"
            width="110"
            sortable
            label="申请时间"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="onSelectCostFn(scope.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="$emit('update:isShow', false)">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  name: 'CostPayment',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  data() {
    return {
      loading: false,
      tableData: [],
      projectList: [],
      BusinessList: [],
      parameter: {
        expenseNumber: null,
        collectionUnit: null,
        applyType: 'APPROVAL_PASS',
        pageNow: 1,
        pageSize: 99999,
      },
      projectId: null,
      businessManageId: null,
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true,
    },
    prefixion: {
      type: String,
      default: '',
      required: true,
    },
  },
  created() {},
  computed: {},
  filters: {
    applyAmount(n) {
      if (n) return Number(n).toLocaleString()
      return n
    },
  },
  watch: {
    isShow: {
      handler: function (val) {
        if (val) {
          this.init()
        } else {
          this.parameter = {
            expenseNumber: null,
            collectionUnit: null,
            applyType: 'APPROVAL_PASS',
            pageNow: 1,
            pageSize: 99999,
          }
          this.projectId = null
          this.businessManageId = null
        }
      },
    },
    prefixion: {
      immediate: true,
      handler: function (val) {
        if (val === 'payment') {
          this.$api.project
            .projectList({
              pageSize: 10000,
              pageNow: 1,
            })
            .then(res => {
              this.projectList = res.data ? res.data : []
            })
        } else if (val === 'businessOutsourceCost') {
          this.$api.businessOutsourceCost
            .getBusinessNameListByPrincipal()
            .then(res => {
              this.BusinessList = res.data
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
  },
  methods: {
    onSearchFn() {
      if (this.prefixion === 'payment') {
        this.parameter.projectId = this.projectId
      }
      if (this.prefixion === 'businessOutsourceCost') {
        this.parameter.businessManageId = this.businessManageId
      }
      this.init()
    },
    handleReset() {
      this.parameter = {
        expenseNumber: null,
        collectionUnit: null,
        applyType: 'APPROVAL_PASS',
        pageNow: 1,
        pageSize: 99999,
      }
      this.init()
    },
    onSelectCostFn(row) {
      this.$api[this.prefixion]
        .selectDetailById(row.id)
        .then(res => {
          this.$emit('onSelectCostFn', res.data)
          this.$emit('update:isShow', false)
        })
        .catch(err => {
          this.$message.error('请求失败')
          console.log(err)
        })
    },
    init() {
      this.loading = true
      this.$api[this.prefixion]
        .getApplyForList(this.parameter)
        .then(res => {
          this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe !important;
  border: 1px solid #ddd !important;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.labelClassNameId {
  width: 13%;
}
</style>
